import React from 'react'
import {Card, Button, Form} from 'react-bootstrap';

const ExpText = () => {
    return(
        <>
        <div class="text-zone">
            <header>
                <h2>Skills &amp; Experience</h2>
                </header>
                <p>My path to becoming a fullstack developer started many years ago as a young engineer working at NASA.  That is where I realized the power or developing custom software in my efforts to analyze and process mass amounts of instrumentation data. Since then I became passionate about software and began learning various skills to complement data analytics and visualization.</p>
                <p>More recent in my journey (last 5 years); I have been near full time as a fullstack developer at K Sciences in projects ranging from full Python desktop applications to full on IoT web packages to operate, store, analyze, and visualize custom instrumention.  This is specifically where I became even more focused on building clean fullstack applications to be intuiavtive to the user experience while also being visually pleasing.</p>
                <p>Using all of my past experience, over the last 2 years I have focused on developling a skillset to create Web3.0 applications. I have built small projects, completed a bootcamp and from scratch created a full deployed Web3.0 project. With utilization of blockchain technology and specifically smart contracts, I plan to create tools that will inspire people to seek out and chase dreams while at same time lessing the concern of failure and fear.</p>

        </div>
        </>
    )
}

export default ExpText