import './Slide_0.css';
import React from 'react'
import bg from "./image-from-rawpixel-id-2381294-original.png"

const ImgCard = () => {
  // let imgpath = require("./image-from-rawpixel-id-2381294-original.png")
  // console.log(bg)
  return (
    <div>
      <img src={bg} id="s1_bg_img" className="imgBg" />
    </div>
  )
  
}


export default ImgCard





// 