import './Slide_3.css';
import React, { Component } from 'react'
import {Card, Button, Form} from 'react-bootstrap';
const KsciCard = () => {
  return (
    <>
    <div id='k_python' className="card kcard">
      <div className="card-body">
        <div className="pro_head">
          <h1>Python Desktop Applications (5+)</h1>
        </div>
        <h4>Data Vision System for GeneCapture with imaging Analytics</h4>
        <h4>Magnetic Field Sensor Application</h4>
        <h4>Blood Velocity Sensor Application</h4>
      </div>
    </div>  
  </>    
  )
  
}


export default KsciCard