import React, { Component } from 'react'
import {Card, Button, Form} from 'react-bootstrap';
const ProjectCard = () => {
  return (
    <>
    <div id="ksci_fs" className="card pro_card kcard">
      <div className="card-body">
        <div className="pro_head">
          <h1>Fullstack Projects:</h1>
        </div>
        <h2>IoT Dianogstic Software</h2>
        <h4>Python Backend</h4>
        <h4>Flask Server</h4>
        <h4>SQL Database</h4>
        <h4>HTML, CSS</h4>
        <h4>Javascript, JQuery</h4>
        <h2>IoT Knee / Leg Stability Sensor</h2>
        <h4>Python Backend</h4>
        <h4>Flask Server</h4>
        <h4>SQL Database</h4>
        <h4>HTML, CSS</h4>
        <h4>Javascript, JQuery</h4>
      </div>
    </div>  
  </>    
  )
  
}


export default ProjectCard